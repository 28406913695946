<template>
  <nav aria-label="Page navigation" v-if="totalPages > 1">
    <ul class="pagination">
      <li class="page-item" :class="{ disabled: current === 1 }">
        <a class="page-link" @click="prev">
          <app-icon name="icon-minimal-left" />
        </a>
      </li>
      <li
        class="page-item"
        v-for="page in availablePages"
        :key="page"
        :class="{ active: current === page }"
      >
        <a
          class="page-link"
          v-if="inRange(page) || page <= 2 || page >= totalPages - 1"
          @click="goTo(page)"
          >{{ page }}</a
        >
        <a class="page-link" :href="`#${page}`" v-else>
          ...
        </a>
      </li>
      <li class="page-item" :class="{ disabled: current === totalPages }">
        <a class="page-link" @click="next">
          <app-icon name="icon-minimal-right" />
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import AppIcon from "./AppIcon";

export default {
  name: "AppPagination",
  components: { AppIcon },
  props: {
    current: Number,
    totalPages: Number
  },
  computed: {
    lower() {
      return Math.max(this.current - 2, 1);
    },
    upper() {
      return Math.min(this.current + 2, this.totalPages);
    },
    availablePages() {
      return Array.from(Array(this.totalPages), (_, i) => i + 1).filter(n => {
        return this.inRange(n) || n <= 2 || n + 1 === this.lower;
      });
    }
  },
  methods: {
    inRange(n) {
      return n <= this.upper && n >= this.lower;
    },
    goTo(page) {
      this.$emit("goTo", page);
    },
    next() {
      const nextPage = Math.min(this.current + 1, this.totalPages);
      this.goTo(nextPage);
    },
    prev() {
      const prevPage = Math.max(this.current - 1, 1);
      this.goTo(prevPage);
    }
  }
};
</script>
