import axios from "@/core/axios";

async function getDashboardUser() {
  try {
    return await axios({}).get("/dashboard/user");
  } catch (e) {
    throw e;
  }
}

async function getMyEmails(payload) {
  try {
    return axios({}).get("/dashboard/myemails", {
      params: {
        page: payload.page,
        perPage: payload.perPage,
      },
    });
  } catch (e) {
    throw e;
  }
}

async function getMyApplication(payload) {
  try {
    return axios({}).get("/dashboard/myapplications", {
      params: {
        perPage: payload.per_page,
        page: payload.page,
        companyFilter: payload.companyFilter,
      },
    });
  } catch (e) {
    throw e;
  }
}

async function getQuestions(payload) {
  try {
    return axios({}).get(`/dashboard/v2/questions`, {
      params: {
        isAnswered: payload.isAnswered,
        perPage: payload.items,
        page: payload.page,
        matchId: payload.matchId,
        sort: payload.sort,
        order: "desc",
        search: payload.search,
        fromDate: payload.fromDate,
        toDate: payload.toDate,
      },
    });
  } catch (e) {
    throw e;
  }
}

async function getScrapers() {
  try {
    return await axios({}).get("/dashboard/scrapers");
  } catch (e) {
    throw e;
  }
}

async function updateQuestion(payload) {
  try {
    return axios({}).post(`/dashboard/v2/questions`, {
      answers: payload.answers,
    });
  } catch (e) {
    throw e;
  }
}

async function billing() {
  try {
    return axios({}).get("/dashboard/billing");
  } catch (e) {
    throw e;
  }
}

async function getSubscription() {
  try {
    return axios({}).get("/dashboard/subscription");
  } catch (e) {
    throw e;
  }
}

async function updateMatchesInterviewStatus(payload) {
  try {
    return axios({}).put(`/dashboard/match/${payload.matchId}/interview`, {
      interviewStatus: payload.interviewStatus,
    });
  } catch (e) {
    throw e;
  }
}

async function updateOnboardingSteps(step) {
  try {
    return axios({}).put(`/dashboard/onboardingSteps`,step);
  } catch (e) {
    console.log(e)
    throw e;
  }
}

async function putScreeningQuestions(screeningQuestions) {
  try {
    return axios({}).put(`/dashboard/user/screening-questions`,screeningQuestions);
  } catch (e) {
    console.log(e)
    throw e;
  }
}


async function getScreeningQuestions() {
  try {
    return axios({}).get("/dashboard/user/screening-questions");
  } catch (e) {
    throw e;
  }
}


export const dashboardRepo = {
  getMyEmails,
  getMyApplication,
  getQuestions,
  getScrapers,
  updateQuestion,
  getDashboardUser,
  billing,
  getSubscription,
  updateMatchesInterviewStatus,
  updateOnboardingSteps,
  putScreeningQuestions,
  getScreeningQuestions
};
