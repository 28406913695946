export const state = {
  user: {},
  init: false,
  status: "",
  justRegistered: false,
  loading: false,
  cvs: [],
  uploadedCV: {
    cvId: 0,
    fileName: "",
  },
};
