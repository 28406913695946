import { ALL_ROLES, Role } from "@/helpers/enums/eRole";
import { defineAsyncComponent } from 'vue';

const Profile = defineAsyncComponent(() => import('@/pages/Profile.vue'));
const InitialPricingSelection = defineAsyncComponent(() => import('@/pages/subscription_plan/InitialPricingSelection.vue'));
const ProfileInfo = defineAsyncComponent(() => import('@/pages/user/Profile/ProfileInfo.vue'));
const PricingPlans = defineAsyncComponent(() => import('@/components/subscription_plan/PricingPlans.vue'));
const Settings = defineAsyncComponent(() => import('@/pages/settings/settings.vue'));
const SmtpSettings = defineAsyncComponent(() => import('@/components/profile/SmtpSettings.vue'));
const Questions = defineAsyncComponent(() => import('@/pages/user/Questions/questions.vue'));
const CompleteProfile = defineAsyncComponent(() => import('@/pages/user/CompleteProfile/completeProfile.vue'));
const ManualApplication = defineAsyncComponent(() => import('@/pages/client/ManualApplication.vue'));
const ProfileQuestions = defineAsyncComponent(() => import('@/pages/user/Profile/ProfileQuestions.vue'));
const ProfileAccountSettings = defineAsyncComponent(() => import('@/pages/user/Profile/ProfileAccountSettings.vue'));

export default [
  {
    path: "/profile",
    name: "profile",
    redirect: { name: "profile-info" },
    component: Profile,
    meta: { authorized: Role.ALL_ROLES },
    children: [
      {
        path: "info",
        name: "profile-info",
        component: ProfileInfo,
        meta: { authorized: Role.ALL_ROLES },
      },
      {
        path: "pricing",
        name: "profile-pricing",
        component: PricingPlans,
        meta: { authorized: Role.ALL_ROLES },
      },
      {
        path: "smtp",
        name: "profile-smtp",
        component: SmtpSettings,
        meta: { authorized: [Role.USER, Role.MANAGED_USER] },
      },
      {
        path: "accountSettings",
        name: "profile-accountSettings",
        component: ProfileAccountSettings,
        meta: { authorized: Role.ALL_ROLES},
      }
      ,
      {
        path: "questions",
        name: "profile-questions",
        component: ProfileQuestions,
        meta: { authorized: [Role.USER, Role.MANAGED_USER] },
      },
    ],
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: { authorized: [Role.ADMIN, Role.SupportAgent] },
  },
  {
    path: "/setuppricing",
    name: "InitialPricingSelection",
    component: InitialPricingSelection,
    meta: { layout: "auth" },
  },
  {
    path: "/questions/:clientId",
    name: "QuestionsClient",
    component: Questions,
    props: true,
    meta: { authorized: [Role.ADMIN, Role.SupportAgent, Role.MANAGER] },
  },
  {
    path: "/questions",
    name: "Questions",
    component: Questions,
    meta: { authorized: [Role.USER, Role.MANAGED_USER] },
  },
  {
    path: "/completeProfile",
    name: "CompleteProfile",
    component: CompleteProfile,
    meta: { layout: "auth" },
  },
  {
    path: "/manualApplication/:clientId",
    name: "ManualApplication",
    component: ManualApplication,
    props: true,
    meta: { authorized: [Role.ADMIN, Role.SupportAgent] },
  },
];
