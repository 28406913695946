import { amplitudePluginSingleton } from "@/plugins/amplitudePlugin.js";
import * as Sentry from "@sentry/vue";

const sentryEnabled = process.env.VUE_APP_SENTRY === "enable";

export function setUser(state, payload) {
  try {
    if (!payload) {
      state.user = null;
      return;
    }

    state.user = {
      ...payload,
      id: payload.userId,
      pricingPlan: payload.pricingPlan || 0,
      is_partner: payload.role === "admin" || payload.role === "manager" || payload.role === "support-agent",
    };

    amplitudePluginSingleton.identify(payload.userId, {
      userId: payload.userId,
      pricingPlan: payload.pricingPlan,
      loopCount: payload.automations ? Object.keys(payload.automations).length : 0,
    });
    if (sentryEnabled) {
      try {
        Sentry.setUser({ id: payload.userId, userId: payload.userId });
      } catch (e) {
        console.warn(e);
      }
    }
  } catch (e) {
    console.log(e);
  }
}

export function updateUserData(state, payload) {
  state.user = {
    ...state.user,
    ...payload,
  };
}

export function setStatus(state, payload) {
  state.status = payload;
}

export function setLoading(state, payload) {
  state.loading = payload;
}

export function setInit(state, payload) {
  state.init = payload;
}

export function setCvs(state, payload) {
  state.cvs = payload;
}

export function setUploadedCV(state, payload) {
  state.uploadedCV.cvId = payload.cvId;
  state.uploadedCV.fileName = payload.fileName;
}

export function adjustLoopCount(state, payload) {
    state.user.loopCount += payload;
}

export function setJustRegistered(state, payload) {  
  state.justRegistered = payload;
}


export function optimisticOnboardingStep(state, { field, value }) {
  if (state.user && state.user.onboardingSteps) {
    state.user.onboardingSteps[field] = value;
  }
}