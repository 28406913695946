<template>
  <button :type="type" :class="btnClass" :disabled="disabledButton" v-on:click="this.handleOnClick" ref="button">
    <label :class="labelClass" v-if="!loading">
      <Pencil v-if="this.buttonStyle === 'edit'" class="pencil" :size="19" />
      <Delete v-if="this.buttonStyle === 'delete'" class="delete-icon" :size="20" />
      <SaveIcon v-if="this.buttonStyle === 'save'" class="save-icon" :size="20" />
      <CloseIcon v-if="this.buttonStyle === 'clear'" class="clear-icon" :size="20" />
      {{ title }}</label
    >
    <span v-if="loading"><LoadingIcon :size="20" :title="'loading'" :fillColor="loadingColor" /></span>
  </button>
</template>

<script>
// @ts-nocheck

import { LoadingIcon } from "@/assets/fonts/icons/index";
import Pencil from "vue-material-design-icons/Pencil.vue";
import Delete from "vue-material-design-icons/Delete.vue";
import SaveIcon from "vue-material-design-icons/CheckboxMarkedCircleOutline.vue";
import CloseIcon from "vue-material-design-icons/Close.vue";
export default {
  components: {
    LoadingIcon,
    Pencil,
    Delete,
    SaveIcon,
    CloseIcon,
  },

  props: {
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "button",
      validator(value) {
        return ["button", "submit", "reset"].includes(value);
      },
    },
    buttonStyle: {
      type: String,
      validator: value => {
        if (typeof value === "string") {
          return ["default", "outlined", "grey", "delete", "clear", "edit", "save", "disabled", "blue"].indexOf(value) !== -1;
        } else {
          return false;
        }
      },
      default: "default",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled:{
      type:Boolean,
      default:false
    }
  },

  data() {
    return {
      btnClass: "button button2",
      disabledButton: false,
      loadingColor: "white",
      labelClass: "icons button2",
      click: true,
    };
  },

  created() {
    if (this.fullWidth) {
      this.btnClass = this.btnClass + " fullWidth";
    } else {
      this.btnClass = this.btnClass.replace(" fullWidth", "");
    }
    if (this.buttonStyle) {
      this.btnClass = this.btnClass + ` button_${this.buttonStyle}`;
    } else {
      this.btnClass = this.btnClass.replace(` button_${this.buttonStyle}`, "");
    }
    this.disabledButton = this.disabled;

    if (this.title.length > 20) {
      this.labelClass = this.labelClass.replace("button2", "button3");
    }
  },

  watch: {
    btnList(value) {
      if (value) {
        this.btnClass = this.btnClass + " btnList";
      } else {
        this.btnClass = this.btnClass.replace(" btnList", "");
      }
    },

    loading(value) {
      if (value) {
        this.btnClass = this.btnClass + " loading-btn";
      } else {
        this.btnClass = this.btnClass.replace(" loading-btn", "");
      }
    },

    disabled(value) {
      this.disabledButton = value;
    },

    buttonStyle(value) {
      if (value) {
        this.btnClass = this.btnClass.replace(` button_outlined`, "");
        this.btnClass = this.btnClass.replace(` button_grey`, "");
        this.btnClass = this.btnClass.replace(` button_delete`, "");
        this.btnClass = this.btnClass.replace(` button_clear`, "");
        this.btnClass = this.btnClass.replace(` button_edit`, "");
        this.btnClass = this.btnClass.replace(` button_save`, "");
        this.btnClass = this.btnClass.replace(` button_disabled`, "");
        this.btnClass = this.btnClass.replace(` button_blue`, "");
        this.btnClass = this.btnClass + ` button_${value}`;
      } else {
        this.btnClass = this.btnClass.replace(` button_outlined`, "");
        this.btnClass = this.btnClass.replace(` button_grey`, "");
        this.btnClass = this.btnClass.replace(` button_delete`, "");
        this.btnClass = this.btnClass.replace(` button_clear`, "");
        this.btnClass = this.btnClass.replace(` button_edit`, "");
        this.btnClass = this.btnClass.replace(` button_save`, "");
        this.btnClass = this.btnClass.replace(` button_disabled`, "");
        this.btnClass = this.btnClass.replace(` button_blue`, "");
      }
    },

    fullWidth(value) {
      if (value) {
        this.btnClass = this.btnClass + " fullWidth";
      } else {
        this.btnClass = this.btnClass.replace(" fullWidth", "");
      }
    },

    title(value) {
      if (value.length > 10) {
        this.labelClass = this.labelClass.replace("button2", "button3");
      }
    },
  },

  methods: {
    handleOnClick() {
      if (this.$attrs && this.$attrs.onClick && this.click && !this.loading) {
        this.click = false;
        this.$emit("click");
        this.click = true;
      }
    },
  },
};
</script>

<style scoped>
@import "./button.css";
</style>

<!--
   <Button
    :title="'Login'"
    :type="'submit'"
    :buttonStyle=''
    :fullWidth="true"
    :loading="false"
    @click=""/>gf
 -->
