import router from "../router";
import { amplitudePluginSingleton } from "../../plugins/amplitudePlugin.js";

router.beforeEach((to, from, next) => {
  amplitudePluginSingleton.logEvent("ROUTER_NAVIGATION", {
    fromFullPath: from.fullPath,
    fromName: from.name,
    fromPath: from.path,
    toFullPath: to.fullPath,
    toName: to.name,
    toPath: to.path
  });

  return next();
});
